import React, { useState } from "react";
import "../assets/css/new-style.css";
import ChatIcon from "../assets/images/chat.svg";
import CrossIcon from "../assets/images/cross.svg";
import Logo from "../assets/images/Alogorithm-Avengers.svg";
import ChatGif from "../assets/images/chat.gif";
function Chatbot() {
  const [isChatbotVisible, setIsChatbotVisible] = useState(false);
  const [messages, setMessages] = useState([]);
  const [userInput, setUserInput] = useState("");
  const [messageData, setMessageData] = useState([]);

  const addMessage = (content, className, suggestion = []) => {
    setMessages((prevMessages) => [
      ...prevMessages,
      { content, className, suggestion },
    ]);
    setMessageData((prevMessages) => [
      ...prevMessages,
      { content, className, suggestion },
    ]);
  };

  const toggleChatbotVisibility = () => {
    setIsChatbotVisible(!isChatbotVisible);
  };

  const sendMessage = () => {
    const userMessage = userInput.trim();
    if (userMessage) {
      addMessage(userMessage, "user-message");
      setUserInput("");
      getBotResponse(userMessage);
    }
  };

  const getBotResponse = async (userMessage) => {
    try {
      // const response = await fetch("https://jsonplaceholder.typicode.com/posts/1");
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/chat/bot`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ message: userMessage }),
        }
      );
      const data = await response.json();

      console.log("data", data);

      let botResponseContent;

      if (data.reply) {
        botResponseContent = <div>{data.reply}</div>;
      } else {
        botResponseContent = "I'm here to answer your questions!";
      }

      addMessage(botResponseContent, "bot-message", [
        { id: 1, name: "Web Solutions" },
        { id: 2, name: "Iot Solution" },
        { id: 3, name: "Web Solutions" },
      ]);
    } catch (error) {
      console.error("Error fetching bot response:", error);
      addMessage("Sorry, I couldn't fetch a response.", "bot-message");
    }
  };
  return (
    <div>
      <button className="chat-bot-btn" onClick={toggleChatbotVisibility}>
        {isChatbotVisible ? (
          <img src={CrossIcon} alt="Chatbot" />
        ) : (
          <img src={ChatIcon} alt="Chatbot" />
        )}
      </button>
      {isChatbotVisible && (
        <div className="chatbot-container">
          <div className="chatbot-header">
            <img src={Logo} alt="Chatbot" className="logo-chatbot" /> What can I
            help with?
          </div>
          <div className="chat-window" id="chatWindow">
            {messageData?.length > 0 ? "" : <img src={ChatGif} />}

            {messageData.map((message, index) => (
              <>
                <div
                  key={index}
                  className={`chat-message ${message.className}`}
                >
                  {message.content}
                </div>
                {message.suggestion && message.suggestion.length > 0 && (
                  <div className="chat-btn-cover">
                    {message.suggestion.map((button, idx) => (
                      <button key={idx} className="chat-suggest-btn">
                        {button.name}
                      </button>
                    ))}
                  </div>
                )}
              </>
            ))}
          </div>
          <div className="input-section">
            <input
              type="text"
              className="input-field"
              placeholder="Type a message..."
              value={userInput}
              onChange={(e) => setUserInput(e.target.value)}
            />
            <button onClick={sendMessage} className="send-button">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-send"
                viewBox="0 0 16 16"
              >
                <path d="M15.854.146a.5.5 0 0 1 .11.54l-5.819 14.547a.75.75 0 0 1-1.329.124l-3.178-4.995L.643 7.184a.75.75 0 0 1 .124-1.33L15.314.037a.5.5 0 0 1 .54.11ZM6.636 10.07l2.761 4.338L14.13 2.576zm6.787-8.201L1.591 6.602l4.339 2.76z" />
              </svg>
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default Chatbot;
